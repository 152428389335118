.CabinetContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 25px;
    margin: 25px 0px 55px 0px;
}
.text{
    width: 30rem;
}
.ImageCabinet{
    width: 18rem;
    height: 18rem;
    border-radius: 10px;
    border: 0.5px solid rgba(0, 0, 0, 0.336);
}

.MidSection{
    background-color: #D9D9D9 ;
    padding: 25px;
}

.titreSec1{
color: #00AE9F ;
}
@media (max-width : 762px){
    .CabinetContainer{
        flex-direction: column;
    }
    .text{
        width: auto;
    }
    .ImageCabinet{
        margin: auto;
    }
}