.ficheForm{
    background: #D9D9D9;
}
.act{
    margin-top: 25px;
    height: 35px;
}
.PlusIcon{
    width: 30px;
    height: 30px;
    color: white;
}