.imprimer{
    position: absolute;
    bottom: 130px;
    right: 250px;
}

@media print {
    .no-print {
        display: none;
    }
}
.facture-container {
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

.facture-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.facture-table th, .facture-table td {
    text-align: center;
}

.facture-table tfoot td {
    font-weight: bold;
}

.dropdown-toggle-custom {
    color: #007bff;
    cursor: pointer;
    font-weight: bold;
    padding: 5px 10px;
}

.custom-dropdown-menu {
    width: 300px;
    max-height: 400px;
    overflow-y: auto;
}

.dropdown-search-input {
    width: 90%;
    margin: 5px auto;
    padding: 5px;
}

.dropdown-items li {
    padding: 10px;
    cursor: pointer;
}

.add-btn {
    background-color: #28a745;
    border: none;
}

.add-btn:hover {
    background-color: #218838;
}

.payment-status .form-check-label {
    font-weight: bold;
}

.payment-status .form-check-input {
    margin-right: 10px;
}

.row-hover {
    position: relative; /* Establishes a positioning context for the button */
}

.remove-button {
    display: none; /* Hide the button by default */
    position: absolute; /* Positioning for hover effect */
    right: 0; /* Aligns the button to the right */
}

.row-hover:hover .remove-button {
    display: block; /* Show the button on hover */
}


.icon-large {
    font-size: 34px;
}
