.calenderContainer{
    background: #D9D9D9;
}
.calendar{
    border: 1px solid black;
    margin: auto;
    text-align: center;
}
.calendar td{
    border: 1px solid black;
    padding: 10px;
    width: 10rem;
}
.calendar th{
    background: #D9D9D9;
    border: 0.5px solid black;
}
.calendar tbody{
    background: white;
}

.calendar tr td:nth-child(1){
    background: #D9D9D9;
    font-weight: bold;
}
#today{
    background-color: #00AE9F;
}