.ImageRDV{
    width: 20rem;
    height: 22rem;
    border-radius: 5px;
}

span{
    color: #00AE9F;
    font-weight: bold;
}

.text{
    width: 30rem;
    font-weight: 500;
    margin-top: 50px;
}
.Date{
    margin-left: 5px;
    width: 25px;
}
.input{
    margin: 10px;
    border-radius: 10px;
    padding: 3px;
    width: 15rem;
}
.RadioGroup{
    margin: 10px;
}
.RedioIn{
    margin: 5px;
}
.RDVForm{
    display: flex;
    flex-direction: row;
}
.leftForm{
    display: flex;
    flex-direction: column;
    margin-right: 25px;
}
@media (max-width : 762px){
    .ImageRDV{
        margin: auto;
    }
    .text{
        margin: auto;
        width: 90%;
    }
    .RDVForm{
        flex-direction: column;
        justify-content: center;
    }

    .leftForm{
        justify-content: center;
        margin: auto;
    }
}