.LoginImg{
    width: 682px;
    height: 377px;
    overflow: hidden;
}
.inputLogin{
    width: 18rem;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 25px;
}
.formContainer{
    border: 2px solid black;
    border-radius: 7px;
}

@media (max-width : 762px){
   .LoginImg{
        display: none;
        visibility: hidden;
        width: 0px;
        height: 0px;
    }
    .inputLogin{
        width: auto;
    }
    .formContainer{
        border: none;
    }
}