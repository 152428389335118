.Description{
    text-align: center;
    width: 35rem;
}

@media(max-width : 760px){

    .Description{
        width: 16rem;
    }
    
}