.logo{
    width: 150px;
  }

  .navSec{
    font-weight: bold;
}

  @media (max-width : 762px){
    .logo{
      width: 100px;
    }
  }

