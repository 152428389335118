.Footer{
    background-color: rgb(235, 235, 235);
    margin-top: 50px;
    padding: 15px;
    display: flex;
    flex-direction: row; 
}

.ContactezNous{
    color: #EC2323;
}
.SocialMediaIcons{
    width: 40px;
    height: 30px;
}


.Footer {
    background-color: #f8f9fa; 
    padding: 20px; 
    border-top: 1px solid #dee2e6; 
    text-align: center; 
}

.ContactezNous {
    font-size: 2rem; 
    color: #343a40; 
    margin-bottom: 20px;
}

.Footer h3 {
    font-size: 1.25rem; 
    color: #495057; 
    margin: 10px 0; 
}

.SocialMediaIcons {
    font-size: 1.5rem; 
    color: #007bff; 
    margin: 0 10px; 
    cursor: pointer; 
}
.SocialMediaIconsInstagram {
    font-size: 1.73rem; 
    background: linear-gradient(to right, #C13584, #E1306C, #F56040, #FCAF45);
    color: white;
    -webkit-text-fill-color: transparent;
    margin: 0 10px; 
    cursor: pointer;
    border-radius: 10px;
}

.SocialMediaIcons:hover {
    color: #0056b3; 
}
.SocialMediaIconsInstagram:hover {
    background: linear-gradient(to right, #9b296a, #b12554, #ca5034, #c48835);
}
.map-container {
    margin-top: 20px; 
    width: 100%; 
    max-width: 800px; 
    margin-left: auto; 
    margin-right: auto; 
}




@media (max-width : 762px) {
    .Footer{
        display: flex;
        flex-direction: column;
    }

}
