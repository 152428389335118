.factures-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .facture-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .facture-item {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
  }
  
  .facture-item p {
    margin: 5px 0;
    color: #555;
  }
  
  .facture-item strong {
    color: #000;
  }
  