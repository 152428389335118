.Notifcation{
    position: absolute;
    width: 42px;
    height: 42px;
    background-color: #CD0C0C;
    color: white;
    font-weight: bold;
    border-radius: 100%;
    text-align: center;
    top: 15px;
    left: 15px;
}